import UIkit from 'uikit';
import './uikit.scss';
import "@babel/polyfill"; // todo continue here by installing npm i -D @babel/polyfill and then finding out, why the fuck it does not work
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);
window.UIkit = UIkit;

window.resetCSRFFromResponse = function (responseText) {
    var response = JSON.parse(responseText);
    var csrf = response['csrf'];
    var nameKey = csrf['name'];
    var valueKey = csrf['value'];
    var name = csrf[nameKey];
    var value = csrf[valueKey];
    document.querySelector(`input[name="csrf.name"]`).value = nameKey;
    document.querySelector(`input[name="csrf.value"]`).value = valueKey;
    document.querySelector(`input[name="${nameKey}"]`).value = name;
    document.querySelector(`input[name="${valueKey}"]`).value = value;
}
window.getCSRFDataFromForm = function () {
    var nameKey = document.querySelector(`input[name="csrf.name"]`).value;
    var valueKey = document.querySelector(`input[name="csrf.value"]`).value;
    var name = document.querySelector(`input[name="${nameKey}"]`).value;
    var value = document.querySelector(`input[name="${valueKey}"]`).value;
    return {
        nameKey: nameKey,
        valueKey: valueKey,
        name: name,
        value: value,
    }
}

window.root = function () {
    const protocol = window.location.protocol;
    const host = window.location.host;
    return `${protocol}//${host}`;
}

document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.uk-navbar-dropdown').forEach(function (el) {
        UIkit.dropdown(el);
    });
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('js', new Date());
    var googleId = 'G-KBTC0Z7NJT';
    gtag('config', googleId);
});

if ('serviceWorker' in navigator) {
    // window.addEventListener('load', () => {
    //     navigator.serviceWorker.register('assets/service-worker.js').then(registration => {
    //         console.log('SW registered: ', registration);
    //     }).catch(registrationError => {
    //         console.log('SW registration failed: ', registrationError);
    //     });
    // });
}
